import React from "react"
import { graphql } from "gatsby"
import tw, { styled } from "twin.macro"
import Layout from "components/layout"
import VideoModal from "components/sections/video-modal"
import HighlightsBG from "images/bg/demand-academy-body.jpg"
import TopoBG from "images/bg/topo.jpg"
import SlantedSection from "components/sections/slant"
import Demand360Ad from "components/demand360-ad"
import BasicBackgroundSection from "components/basic-background-section"
import BgApplications from "images/bg/applications.jpg"
import MeasurementAttributes from "components/sections/measurement-attributes"
import LightSlantedSection from "components/sections/light-slant"
import ArticleShowcase from "components/sections/article-showcase"
import Quotes from "components/sections/quotes"
import PartnerLogos from "components/partner-logos"
import BgWaves from "images/bg/waves-purple.jpg"
import ProductCards from "components/product-cards"
import Industries from "components/industries"
import IndustryReinforcementsContent from "components/industry-reinforcements-content"
import DemandGlobeContent from "components/sections/demand-globe-content"
import { getSharePreviewUrls } from "utils/share-preview"
import SEO from "components/seo"
import { AttentionEconomyCarousel } from "../components/AttentionEconomyCarousel/AttentionEconomyCarousel"

const PageStyles = styled.div`
  img.hero {
    @media (max-width: 640px) {
      width: 240vw;
      max-width: initial;
      margin-left: -120vw;
      left: 50%;
      position: relative;
    }
  }

  p {
    ${tw`font-body text-white`}
    font-size: 13px;
    line-height: 25px;
    margin-bottom: 25px;
    @media (min-width: 640px) {
      font-size: 15px;
      line-height: 27px;
      margin-bottom: 27px;
    }
  }

  h1,
  h2 {
    ${tw`py-2 w-full font-display font-bold text-white`}

    font-size: 40px;
    line-height: 50px;

    @media (min-width: 640px) {
      font-size: 50px;
      line-height: 62px;
    }

    @media (min-width: 1024px) {
      font-size: 60px;
    }
  }

  h3 {
    ${tw`font-display font-bold text-yellow mt-4 l:mt-0`}
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 10px;
    text-align: center;
  }

  h4 {
    ${tw`text-yellow font-display font-bold`}
    font-size: 14px;
    line-height: 20px;
    @media (min-width: 640px) {
      font-size: 18px;
      line-height: 25px;
    }
  }

  h5 {
    ${tw`font-display font-bold text-white`}
    font-size: 13px;
    line-height: 25px;
    @media (min-width: 640px) {
      font-size: 15px;
      line-height: 27px;
    }
  }
`

const Topo = styled.div`
  background: linear-gradient(180deg, #0d0023 0%, rgba(13, 0, 35, 0) 9.64%),
    url(${TopoBG});
  background-repeat: repeat-y;
  background-size: 500% auto;
  background-position: center top;

  @media (min-width: 640px) {
    background-size: 200% auto;
    background-position: -50px top;
  }

  @media (min-width: 1200px) {
    background-size: cover;
  }
`

const Container = styled.div`
  ${tw`mx-auto l:max-w-l`}
  max-width: 90%;
`

const Highlights = styled.div`
  ${tw` m:mt-12 l:-mt-24 l:relative l:z-10`}

  background: linear-gradient(180deg,
      #000000 0%, rgba(0, 0, 0, 0) 30.02%,
      rgba(13, 0, 35, 0) 83.48%,
      #0D0023 100%
    ),
    url(${HighlightsBG});

  background-size: cover;
  background-color: #0d0023;

  @media (min-width: 640px) {
    background-repeat: no-repeat;
    background-position: center top;
  }

  @media (min-width: 1200px) {
    background-size: 100%;
  }

  h3 {
    ${tw`mt-4`}
  }
`

const PageBackgroundSection = styled.div`
  ${tw`relative`}

  background:  ${({ bg }) => `url(${bg})`};
  background-repeat: repeat-y;
  background-size: 100%;

  @media (max-width: 640px) {
    background-size: 250% !important;
    background-position: 30% 50% !important;
  }
`

const LargeTitle = styled.h2`
  ${tw`py-2 w-full font-display font-bold text-white text-center`}
  font-size: 30px;
  line-height: 35px;
  @media (min-width: 640px) {
    font-size: 45px;
    line-height: 53px;
  }
  @media (min-width: 1024px) {
    font-size: 60px;
    line-height: 79px;
  }
`

const Paragraph = styled.div`
  ${tw`font-body text-white text-center mx-auto`}

  font-size: 15px;
  line-height: 25px;

  @media (min-width: 640px) {
    font-size: 20px;
    line-height: 30px;
  }

  @media (min-width: 1200px) {
    max-width: 800px;
  }
`

const InfoSection = ({ children, id, className, contentBlock }) => (
  <div className={className} id={id}>
    <LargeTitle className="text-center">{contentBlock.title}</LargeTitle>
    <Paragraph className="pt-6 pb-16">
        <div className="text-yellow font-body text-sm m:text-base l:text-xl text-center">
            Designed for executive decision-making across all phases of the content development and monetization lifecycle.
        </div>

    </Paragraph>
    {children}
  </div>
)

const AttentionEconomy = ({ data }) => {
  const { gcms } = data
  const { page, quoteContentBlocks, featuredLogosClients, exploreBlocks } = gcms

  const homepageQuote = quoteContentBlocks?.find(
    ({ identifier }) => identifier === "homepage-quote"
  )

  const exploreBlock = exploreBlocks?.find(
    ({ identifier }) => identifier === "explore-our-products-section"
  )

  const videoTitle = page.contentBlocks?.find(
    ({ identifier }) => identifier === "attention-economy-video-title"
  )
  const industryTitle = page.contentBlocks?.find(
    ({ identifier }) => identifier === "attention-economy-industry-title"
  )

  const partnerLogosTitle = page.contentBlocks?.find(
    ({ identifier }) => identifier === "attention-economy-partner-logos-title"
  )

  return (
    <Layout>
      {!!page && (
        <SEO
          title={page.title}
          description={page.description}
          path={page.path}
          sharePreviewUrls={getSharePreviewUrls(page)}
        />
      )}
      <PageStyles>
        <AttentionEconomyCarousel title={page.headerContent?.html}/>
        <Highlights>
          {videoTitle && (
            <div
              className="px-2 py-4 mt-16 m:mt-32 text-center mx-auto max-w-s m:max-w-m l:max-w-l"
              dangerouslySetInnerHTML={{
                __html: videoTitle.headerContent?.html,
              }}
            ></div>
          )}
          <VideoModal
            autoplayHash="demo"
            className="mt-16 m:mt-24 l:mt-16 relative"
            data={page.extraData}
          />
        </Highlights>
        <Topo>
          <div className="px-2 py-16 m:py-32 flex flex-col justify-center items-center content-center mx-auto max-w-s m:max-w-m l:max-w-l">
            <Paragraph>
                <div
                    className="text-yellow font-body text-sm m:text-base l:text-xl text-center"
                    dangerouslySetInnerHTML={{
                        __html: industryTitle.headerContent?.html,
                    }}
                />

            </Paragraph>
            <Industries />
          </div>
        </Topo>
        <SlantedSection>
          <BasicBackgroundSection
            image={BgApplications}
            color="#000000"
            repeat="repeat-y"
            size="100% auto"
            bgPosition="center"
            className="py-16 m:pb-24 l:py-32"
          >
            <Container>
              <IndustryReinforcementsContent />
            </Container>
          </BasicBackgroundSection>
        </SlantedSection>
      </PageStyles>
      <PageBackgroundSection bg={BgWaves}>
        <div className={"mx-auto py-12 m:py-24 flex flex-col"}>
          <div className="mx-auto w-full l:max-w-l">
            {featuredLogosClients && (
              <div className="pt-4">
                {/*<LargeTitle*/}
                {/*  dangerouslySetInnerHTML={{*/}
                {/*    __html: partnerLogosTitle.headerContent.html,*/}
                {/*  }}*/}
                {/*/>*/}
                <PartnerLogos data={featuredLogosClients} tabletFull />
              </div>
            )}
            {homepageQuote && (
              <Quotes quote={homepageQuote.mainContent?.text || ""} />
            )}
          </div>
        </div>
      </PageBackgroundSection>
      {exploreBlock && (
        <InfoSection
          id="products"
          className="px-2 py-8 m:py-16 m:pb-32 mx-auto m:max-w-m l:max-w-l"
          contentBlock={exploreBlock}
        >
          <ProductCards
            cardKeys={[
              "content-valuation",
              "demand360",
              "ip-topic-demand",
              "affinity-solutions",
              "supply-metadata",
              "plexie",
            ]}
          />
        </InfoSection>
      )}

      <SlantedSection>
        <Demand360Ad />
      </SlantedSection>
      {Boolean(page?.articleShowcase?.length) && (
        <LightSlantedSection>
          <ArticleShowcase articles={page.articleShowcase} />
        </LightSlantedSection>
      )}
    </Layout>
  )
}

export const query = graphql`
  query {
    allTvDemandJson {
      nodes {
        markets {
          country_name
        }
      }
    }
    gcms {
      page(where: { path: "/capabilities" }) {
        ...PageData
      }
      quoteContentBlocks: contentBlocks(
        where: { identifier: "homepage-quote" }
      ) {
        identifier
        mainContent {
          text
        }
      }
      featuredLogosClients(where: { id: "ck5fmc1epylz80996oh8gcayz" }) {
        heading
        headingColor {
          hex
        }
        logos {
          url
          altText
        }
      }
      exploreBlocks: contentBlocks(
        where: { identifier: "explore-our-products-section" }
      ) {
        identifier
        title
        headerContent {
          html
        }
      }
    }
  }
`

export default AttentionEconomy
