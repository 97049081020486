import * as React from "react";
import {styled} from "twin.macro"
import {useState} from "react"
import {motion} from "framer-motion";
import {graphql, useStaticQuery} from "gatsby"


const SlideBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
`;

const Slide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 60px 20px 0 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%);
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  max-height: 950px;
  min-height: 950px;
  transition: opacity 0.35s ease-in-out;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;


  @media (min-width: 850px) {
    padding-top: 100px;
  }

  @media (min-width: 1200px) {
    padding-top: 110px;
  }

  @media screen and (max-width: 768px) and (max-height: 850px) {
    max-height: 950px;
    //min-height: 950px;
  }
  //@media screen and (max-width: 400px) and (max-height: 850px) {
  //  max-height: 950px;
  //}
`;

const SlideContents = styled.div`
  align-items: start;
  margin-left: 23px;
  max-width: 100%;
  @media (min-width: 875px) {
    display: flex;
    margin-top: -40px;
  }
  @media (min-width: 1550px) {
    display: flex;
    margin-top: 50px;
  }
`;

const Slides = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 950px;
  min-height: 950px;
  @media screen and (max-width: 450px) and (max-height: 950px) {
    max-height: 950px;
    min-height: 950px;
  }
`;

const Left = styled.svg`

  display: none;
  position: absolute;
  height: 23px;
  width: 40px;
  left: 19px;
  top: 45%;  /* Center relative to the Slides div */
  transform: translateY(-50%);
  cursor: pointer;
  stroke-linecap: round;
  stroke-linejoin: round;
  overflow: visible;
  stroke-width: 4;
  @media (min-width: 875px) {
    display: block;
  }
  
`;

const Right = styled.svg`
  display: none;
  position: absolute;
  height: 23px;
  width: 40px;
  right: 35px;
  top: 45%;  /* Center relative to the Slides div */
  transform: translateY(-50%);
  cursor: pointer;
  stroke-linecap: round;
  stroke-linejoin: round;
  overflow: visible;
  stroke-width: 4;
  @media (min-width: 875px) {
    display: block;
  }
`;


const Dots = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  position: absolute;
  top: calc(100% - 60px);
  left: 50%;
  transform: translateX(-50%);

  @supports (-webkit-touch-callout: none) {
    top: calc(100% - 60px - env(safe-area-inset-bottom));
  }
`;

const Dot = styled.div`
  height: 13px;
  width: 13px;
  background: white;
  border-radius: 9999px;
  cursor: pointer;
  transition: opacity 0.15s ease-in-out;

  &:hover {
    opacity: 0.8 !important;
  }
`;

const Header = styled.div`
  margin: 0 10px;
  position: fixed;
  top: 110px;
  @media (min-width: 874px) {
    top: 150px;
  }
  h1 {
    font-weight: 700;
    font-size: 24px !important;
    line-height: 28px !important;
    color: white;
    text-align: center !important;
    margin-bottom: 37px;
    margin-left: 10px;
    @media (min-width: 875px) {
      font-size: 36px !important;
      line-height: 45px !important;
      margin-bottom: 0 !important;
      margin-left: 0px;
    }
  }
;

  p:empty {
    display: none;
  }
`


const HeaderTitle = styled.h3`
  font-weight: 700;
  text-align: center !important;
  font-size: 12px !important;
  line-height: 45px;
  margin-bottom: 10px;
  letter-spacing: 4px;
  text-transform: uppercase;
  white-space: nowrap;
  @media (min-width: 875px) {
    text-align: left !important;
  }
`;

const HeaderContent = styled.div`

  p {
    font-family: "Axiforma Bold" !important;
    font-weight: 700;
    font-size: 32px !important;
    line-height: 44px !important;
    margin-bottom: 22px !important;
    @media (min-width: 875px) {
      font-size: 36px !important;
      line-height: 45px !important;
    }
  }

  @media (min-width: 1200px) {
    p {
      font-size: 36px !important;
      line-height: 45px !important;
    }
  }
`;

const MainContent = styled.div`
  //max-width: 487px;
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: white;
    @media (min-width: 875px) {
      max-width: 487px;
      font-size: 18px !important;
      line-height: 27px !important;
    }
  }

  ul {
    list-style-type: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: white;

    @media (min-width: 875px) {
      font-size: 18px;
      line-height: 27px;
      list-style-type: disc;
      margin-left: 23px;
    }
  }
`


const Text = styled.div`
  margin-top: 0px !important;
  flex-basis: 50%;
  flex-shrink: 0;
  text-align: center;

  :first-child {
    transform: translateY(-50px);
  }
;

  @media (min-width: 875px) {
    //margin-top: 73px !important;
    text-align: left;
    max-width: 500px;
    flex-basis: 40%;
    transform: translate(-150px);
    margin-top: 20px !important;
    margin-left: 100px !important;
  }


  @media (min-width: 1100px) {
    margin-top: 115px !important;
    margin-left: 180px !important;
    text-align: left;
    flex-basis: 40%;
    max-width: 500px;
    transform: translateX(-188px);
  }
  @media (min-width: 1550px) {
    margin-top: 180px !important;
    margin-left: 140px !important;
    text-align: left;
    flex-basis: 40%;
    max-width: 500px;
    transform: translateX(-188px);
  }
`;

const ImageContainer = styled.div`
  flex-basis: 50%;
  @media (min-width: 1200px) {
    flex-basis: 50%;
  }
  @media (min-width: 875px) {
    flex-basis: 50%;
  }
  @media (max-width: 874px) {
    //margin-top: 170px;
    margin-top: 75px;
    display: flex;
    justify-content: center; /* Ensures the image stays centered */
  }

`;


const Img = styled.img`
  height: 310px;
  width: auto;
  max-height: unset !important;
  max-width: unset !important;

  @media (min-width: 400px) and (max-width: 599px) {
    height: 330px;
    width: auto;
    margin-top: 10px;
    margin-left: 0 !important;
  }

  @media (min-width: 600px) and (max-width: 874px) {
    height: 410px;
    width: auto;
    margin-left: 0 !important;
  }

  @media (min-width: 875px) {
    height: 500px;
    width: auto;

    margin-top: 0 !important;
    margin-left: 0 !important;
  }

  @media (min-width: 1100px) {
    height: 600px;
    width: auto;

    margin-top: 0 !important;
    margin-left: 0 !important;
  }

  @media (min-width: 1550px) {
    height: 700px;
    width: auto;
    max-height: unset !important;
    max-width: unset !important;
    transform: translateX(-125px);
    margin-top: 0 !important;
    margin-left: 0 !important;
  }

`;

const Shadow = styled.div`
  position: absolute;
  width: 100%;
  height: 200px;
  z-index: -1;
  bottom: 0;
  background: linear-gradient(to bottom, transparent, #000000);
  @media (min-width: 875px) {
    height: 342px;
  }
`

export const AttentionEconomyCarousel = ({title}) => {
    const [index, setIndex] = useState(0);
    const data = useStaticQuery(graphql`
        query {
            gcms {
                contentBlocks(
                    where: { identifier: "capabilities-layer" }
                ) {
                    title
                    id
                    featuredImage {
                        url
                    }
                    icon {
                        url
                    }
                    headerContent {
                        html
                    }
                    mainContent {
                        html
                    }
                    extraData
                }
            }
        }
    `);

    const slides = data.gcms.contentBlocks;
    return (
        <>
            <Slides>

                {slides.map((slide, i) => {
                    const isActive = index === i;
                    return (
                        <div key={i} style={{opacity: isActive ? 1 : 0}}>
                            <SlideBackground style={{
                                backgroundImage: `url("${slide.featuredImage.url}")`,
                                opacity: isActive ? 1 : 0,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                            }}/>
                            <Slide
                                as={motion.div}
                                key={i}

                                onPanEnd={(event, info) => {
                                    if (window.innerWidth > 875) return;

                                    const swipeThreshold = 50; // Minimum pixels to be considered a swipe
                                    if (info.delta.x > swipeThreshold) {
                                        // Swipe Right (Move to Previous Slide)
                                        setIndex((index - 1 + slides.length) % slides.length);
                                    } else if (info.delta.x < -swipeThreshold) {
                                        // Swipe Left (Move to Next Slide)
                                        setIndex((index + 1) % slides.length);
                                    }
                                }}

                                // onPan={(e) => {
                                //
                                //     if (window.innerWidth > 875) return;
                                //
                                //     const direction = e.movementX > 0 ? "left" : "right";
                                //     if (direction === "right") {
                                //         return setIndex((index + 1) % slides.length);
                                //     }
                                //     return setIndex((index - 1 + slides.length) % slides.length);
                                // }}
                            >
                                <Header dangerouslySetInnerHTML={{__html: title}}/>
                                <SlideContents>
                                    <ImageContainer>
                                        <Img src={slide.icon.url}/>
                                    </ImageContainer>
                                    <Text>
                                        <HeaderTitle>{slide.extraData.headerTitle}</HeaderTitle>
                                        <HeaderContent dangerouslySetInnerHTML={{__html: slide.headerContent.html}}/>
                                        <MainContent dangerouslySetInnerHTML={{__html: slide.mainContent.html}}/>
                                    </Text>
                                </SlideContents>
                            </Slide></div>
                    )
                })}
                <Shadow/>
                <Dots>
                    {slides.map((_, i) => (
                        <Dot key={i} style={{opacity: i === index ? 1 : 0.5}} onClick={() => setIndex(i)}/>
                    ))}
                </Dots>
                <Left viewBox="0 0 24 24" fill="none"
                      onClick={() => setIndex((i) => (i - 1 + slides.length) % slides.length)}>
                    <path d="M 13 0 L 1 12" stroke="white"/>
                    <path d="M 13 24 L 1 12" stroke="white"/>
                </Left>
                <Right viewBox="0 0 24 24" fill="none" onClick={() => setIndex((i) => (i + 1) % slides.length)}>
                    <path d="M 11 0 L 23 12" stroke="white"/>
                    <path d="M 11 24 L 23 12" stroke="white"/>
                </Right>
            </Slides>
        </>
    );
}